import * as React from 'react';
import useMovimento from '../../../../../hooks/useMovimentos';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Switch } from '@mui/material';
import { ButtonFechar } from '../../../../button';
import { SacContext } from '../../../../../context';
import { TitleGrupos } from '../../../../typography';
import { getSetor } from '../../../../../utils/sac';
import { orderList } from '../../../../../utils/geral';

export default function DialogMovimentos() {
    const { listMovimento, getMovimentos, putMovimentoAt, loadingMov } = useMovimento();
    const { toggleAcao, sac } = React.useContext(SacContext);

    React.useEffect(() => {
        getMovimentos(getSetor());
    }, [])

    React.useEffect(() => {
        toggleAcao(sac.codigo, 'Movimentos');
    }, [loadingMov])

    function setMovimento(idMov) {
        if (idMov === sac.movimento)
            putMovimentoAt(sac.codigo, 0)
        else
            putMovimentoAt(sac.codigo, idMov)
    }

    function Movimentos(mov, m) {
        return (<ListItem key={m}>
            <ListItemButton onClick={() => setMovimento(mov.codigo)} dense>
                <ListItemIcon>
                    <Switch
                        edge="start"
                        size='small'
                        checked={mov.codigo === sac.movimento}
                        disableRipple
                        inputProps={{ 'value': mov.codigo }}
                        color='green'
                    />
                </ListItemIcon>
                <ListItemText id={mov.nome} primary={mov.nome} />
            </ListItemButton>
        </ListItem>
        );
    }

    return (<>
        <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title='Movimentos' /></Divider>
        <List sx={{ background: 'inherit' }}>
            {listMovimento ? orderList(listMovimento, 'ordem').map(Movimentos) : ''}
        </List >
        <Stack direction='row' spacing={1} justifyContent='flex-end' paddingRight={1} paddingBottom={1}>
            <ButtonFechar />
        </Stack>
    </>);
}
