import React, { useEffect, useState } from "react";
import { ContainerPages } from "../../components/container";
import { getTheme, heightScreen } from "../../utils/theme";
import { Box, ButtonGroup, Paper, Stack, Tab, Tabs } from "@mui/material";
import { StackJustify, StackRight } from "../../components/stack";
import { ZoomIcon, Add, Settings, EditIcon, ListIcon, Thumb, TreeBox } from "../../components/icons";
import { ButtonLinkIcon, ButtonToolTip } from "../../components/button";
import { Title, TitleGrupos, TitleSmall } from "../../components/typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { listaLength } from "../../utils/string";
import { iniFaq } from "../../utils/constructor";
import useFaq from "../../hooks/useFaq";
import  LayoutSac  from "../../components/layout";
import useRastreios from "../../hooks/useRastreios";
import useUsuarios from "../../hooks/useUsuarios";
import { GeralContext } from "../../context";
import { filtrarLista } from '../../utils/geral';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DividerBasic } from '../../components/divider';
import { FAQDialog } from "../../components/dialog";
import { getPerm, getUser } from "../../utils/user";
import { BoxFaq } from "../../components/box";
import { useParams } from "react-router-dom";
import { getSession, setSession } from "../../utils/storage";

export default function ListFaq() {
    const { faqMenu, getFAQMenu, aprovFaq, loadingFAQ, getFaq, faq, getFaqPendentes, getFaqAprovados, listFaqApro, getAnotacaoFaq, listAnFaq, listFaqPend } = useFaq();
    const [at, setAt] = React.useState(false);
    const { getRastreios, rastreios } = useRastreios();
    const [faqSelect, setFaqSelect] = React.useState(iniFaq);
    const { getListUsuarios } = useUsuarios();
    const [open, setOpen] = React.useState(false)
    const { listUsers } = React.useContext(GeralContext);
    const [exibirTodos, setExibirTodos] = useState(false);
    const { codigo } = useParams();
    const [value, setValue] = React.useState(0);
    let listExpanded = getSession('menuExpandedFaq') ? JSON.parse(getSession('menuExpandedFaq')) : ["1", "2"];

    useEffect(() => {
        getFAQMenu();
        getRastreios();
        setAt(false);
        getListUsuarios();
    }, [at === true])

    useEffect(() => {
        getListUsuarios();
    }, [])

    useEffect(() => {
        if (codigo)
            getFaq(codigo)
    }, [])

    useEffect(() => {
        setFaqSelect(faq)
        setElemento(faq)
    }, [faq])

    useEffect(() => {
        if (exibirTodos)
            getFaqAprovados();
    }, [exibirTodos])

    function SelecionarFaq(faq) {
        getAnotacaoFaq(faq.codigo);
        setFaqSelect(faq)
        setElemento(faq)
    }

    function setElemento(faq) {
        let descr = getTheme() === 'light' ? faq.descricao.replaceAll('(241, 241, 241)', 'red')
            : faq.descricao.replaceAll('(51, 51, 51)', '(241, 241, 241)');
        descr = getTheme() === 'light' ? descr.replaceAll('#f4f5fa', '#343944')
            : descr.replaceAll('#343944', '#f4f5fa');
        // descr = descr.replaceAll('http://10.1.1.27:3000', `https://${window.location.hostname}`);
        console.log(descr);
        document.getElementById('conteudo').innerHTML = descr;
        setFaqSelect(faq)
        getAnotacaoFaq(faq.codigo)
    }

    const selecionarFaq = (id, idMenu = '0') => {
        if (listExpanded.indexOf(idMenu.toString()) > 0) {
            listExpanded = listExpanded.filter(value => value !== idMenu.toString());
        } else {
            listExpanded.push(idMenu.toString());
        }
        setSession('menuExpandedFaq', JSON.stringify(listExpanded))
        if (id > 0) {
            getFaq(id);
        } else {
            setElemento(iniFaq);
        }
    }


    function ListarItens(item) {
        let child = faqMenu.filter(it => it.master === item.codigo);
        return <TreeItem key={item.codigo} nodeId={item.codigo + ''} label={<TitleGrupos title={item.descricao} />} onClick={() => selecionarFaq(item.idFAQ, item.codigo)} >
            {child.length === 0 ? "" : child.map(ListarItens)}
        </TreeItem>
    }

    function ListarItensPend(item) {
        return <BoxFaq onClick={() => SelecionarFaq(item)} faq={item} />
    }

    function DetalhesFaq() {
        let listaRastreio = []
        listaRastreio.push(filtrarLista(rastreios, 'codigo', faqSelect.rastreio)[0]);
        if (listaLength(listaRastreio) > 0) {
            for (let i = 0; i < listaLength(listaRastreio); i++) {
                if (listaRastreio[i].master && listaRastreio[i].master > 0) {
                    listaRastreio.push(filtrarLista(rastreios, 'codigo', listaRastreio[i].master)[0]);
                }
            }
        }

        function ListDescrRastreio(r) {
            return <Stack key={r.codigo} direction='row' alignItems='center'>
                {r.master === 0 ? '' : <KeyboardArrowRightIcon color='iconColor' />}
                <TitleSmall title={r.descricao} />
            </Stack>
        }

        return <Box>
            <Title title={faqSelect.titulo} />
            <Stack direction='row' alignItems='center' pt={1}>{listaLength(listaRastreio) > 0 ? listaRastreio.map(ListDescrRastreio).reverse() : ''}</Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                {listaLength(listUsers) > 0 ?
                    <>
                        {faqSelect.criadopor ? <TitleSmall title={`Criado por ${filtrarLista(listUsers, 'codigo', faqSelect.criadopor)[0].login}`} /> : ''}
                        {faqSelect.aprovadopor ? <TitleSmall title={faqSelect.aprovadopor > 0 ? `Aprovado por ${filtrarLista(listUsers, 'codigo', faqSelect.aprovadopor)[0].login}` : ''} /> : ''}
                    </>
                    : ''}
                <Box>
                    {getPerm('LI') ? <ButtonToolTip title='Aprovar' color='green' icon={<Thumb color='green' />} onClick={() => aprovarFaq()} /> : ''}
                    {getPerm('LI') || (faqSelect.criadopor === parseInt(getUser().sub)) ? <ButtonLinkIcon title='Editar' color='blue' icon={<EditIcon color='blue' />} to={`/faq/edit/${faqSelect.codigo}`} /> : ''}
                    <ButtonToolTip title='Ampliar' color='iconColor' icon={<ZoomIcon />} onClick={() => setOpen(true)} />
                </Box>
            </Stack>
        </Box >
    }

    function ListDescricao(r, i) {
        return <Stack key={r.codigo} direction='row' alignItems='center'>
            <span style={{ fontSize: "16px", marginLeft: '-15px' }}>{i + 1}. {r.conteudo} </span>
        </Stack>
    }


    function ListarFaq(item) {
        return <BoxFaq onClick={() => SelecionarFaq(item)} faq={item} />
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        setFaqSelect(0)
        if (newValue === 0)
            getFaqAprovados();
        else
            getFaqPendentes();
        document.getElementById('conteudo').innerHTML = '';
    };

    function aprovarFaq() {
        aprovFaq(faqSelect.codigo)
        setAt(true);
    }

    return (
        <ContainerPages activeAuxBar={false} title='FAQ'>
            <Box p={1}>
                <Paper>
                    <StackJustify>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" TabIndicatorProps={{ sx: { bgcolor: "#ed3237 !important", opacity: '1' } }}>
                            <Tab label="FAQ" value={0} />
                            <Tab label="Pendentes" value={1} />
                        </Tabs>
                        <StackRight>
                            <ButtonLinkIcon title='Novo FAQ' icon={<Add color='green' />} to='/faq/create' />
                        </StackRight>
                    </StackJustify>
                    {value === 0 ?
                        <Box value={value} index={0}>
                            <LayoutSac
                                colL={
                                    <Paper elevation={0} sx={{ padding: '5px 10px 5px 10px' }}>
                                        <StackJustify>
                                            <Title title={exibirTodos ? 'FAQs' : 'Menu'} />
                                            {getPerm('LI') ? <ButtonGroup sx={{ width: '100%', justifyContent: 'end' }}>
                                                {exibirTodos ? '' : <ButtonLinkIcon title='Config. Menu' icon={<Settings />} to='/faq/config' />}
                                                {exibirTodos ?
                                                    <ButtonToolTip title='Menu' color='iconColor' icon={<TreeBox />} onClick={() => setExibirTodos(false)} />
                                                    :
                                                    <ButtonToolTip title='Lista' color='iconColor' icon={<ListIcon />} onClick={() => setExibirTodos(true)} />
                                                }

                                            </ButtonGroup> : ''}
                                        </StackJustify>
                                        {exibirTodos ?
                                            listaLength(listFaqApro) > 0 ? listFaqApro.map(ListarFaq) : <Title title='Não existem FAQs a serem listados.' />
                                            :
                                            <>
                                                <TreeView
                                                    aria-label="file system navigator"
                                                    defaultCollapseIcon={<ExpandMoreIcon color='iconColor' />}
                                                    defaultExpandIcon={<ChevronRightIcon color='iconColor' />}
                                                    defaultExpanded={listExpanded}
                                                >
                                                    {loadingFAQ ? '' : faqMenu.filter(it => it.master === 0).map(ListarItens)}
                                                </TreeView>
                                            </>
                                        }
                                    </Paper>
                                }
                                colR={<>
                                    {faqSelect.codigo > 0 ?
                                        <>
                                            <DetalhesFaq />
                                            <DividerBasic />
                                        </>
                                        : ''}
                                    <div id='conteudo'
                                        style={{
                                            color: getTheme() === 'dark' ? '#f4f5fa' : 'color:rgb(52,57,68)',
                                            width: "100%",
                                            overflow: "auto",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                                            lineHeight: 1.5,
                                            textRendering: 'optimizeLegibility',
                                            fontWeight: '400',
                                            letterSpacing: '0.00938em',

                                        }}
                                    >
                                        <img src='../../../app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)', overflow: 'auto' }} />
                                    </div>
                                    {faqSelect.codigo > 0 ?
                                        <div
                                            style={{
                                                color: getTheme() === 'dark' ? '#f4f5fa' : 'color:rgb(52,57,68)',
                                                width: "100%",
                                                overflow: "auto",
                                                wordWrap: "break-word",
                                                whiteSpace: "normal",
                                                fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                                                lineHeight: 1.5,
                                                textRendering: 'optimizeLegibility',
                                                fontWeight: '400',
                                                letterSpacing: '0.00938em',
                                                textAlign: 'justify !important'
                                            }}>
                                            <ul>
                                                {listaLength(filtrarLista(listAnFaq, 'tipo', 'E')) > 0 ?
                                                    <li>
                                                        <font style={{ fontSize: "18px", fontWeight: 'bold' }}>Implementações:</font><p />
                                                        {filtrarLista(listAnFaq, 'tipo', 'E').map(ListDescricao)}<p />
                                                    </li> : ''}
                                                {listaLength(filtrarLista(listAnFaq, 'tipo', 'C')) > 0 ?
                                                    <li>
                                                        <font style={{ fontSize: "18px", fontWeight: 'bold' }}>Correções:</font><p />
                                                        {filtrarLista(listAnFaq, 'tipo', 'C').map(ListDescricao)}<p />
                                                    </li> : ''}
                                            </ul>
                                        </div> : ''}
                                </>
                                }
                            />
                        </Box> : ''}
                    {value === 1 ?
                        <Box pt={1}>
                            <LayoutSac
                                padrao={listaLength(listFaqPend) > 0 ? listFaqPend.map(ListarItensPend) : <Title title='Não existe FAQ pendente de aprovação.' />}
                                colL={listaLength(listFaqPend) > 0 ? listFaqPend.map(ListarItensPend) : <Title title='Não existe FAQ pendente de aprovação.' />}
                                colR={<Box p={1}>
                                    {faqSelect.codigo > 0 ?
                                        <>
                                            <DetalhesFaq />
                                            <DividerBasic />
                                        </> : ''}

                                    <div id='conteudo'
                                        style={{
                                            color: getTheme() === 'dark' ? '#f4f5fa' : 'color:rgb(52,57,68)',
                                            width: "100%",
                                            overflow: "auto",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            padding: "0 30px 30px 30px ",
                                            fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                                            lineHeight: 1.5,
                                            textRendering: 'optimizeLegibility',
                                            fontWeight: '400',
                                            letterSpacing: '0.00938em',

                                        }}>
                                        <img src='./../../../app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                                    </div>
                                    <div id='conteudo'
                                        style={{
                                            color: getTheme() === 'dark' ? '#f4f5fa' : 'color:rgb(52,57,68)',
                                            width: "100%",
                                            overflow: "auto",
                                            wordWrap: "break-word",
                                            whiteSpace: "normal",
                                            // padding: "0 30px 30px 30px ",
                                            fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                                            lineHeight: 1.5,
                                            textRendering: 'optimizeLegibility',
                                            fontWeight: '400',
                                            letterSpacing: '0.00938em',

                                        }}>
                                        <ul>
                                            {listaLength(filtrarLista(listAnFaq, 'tipo', 'E')) > 0 ?
                                                <li>
                                                    <font style={{ fontSize: "18px", fontWeight: 'bold' }}>Implementações:</font><p />
                                                    {filtrarLista(listAnFaq, 'tipo', 'E').map(ListDescricao)}<p />
                                                </li> : ''}
                                            {listaLength(filtrarLista(listAnFaq, 'tipo', 'C')) > 0 ?
                                                <li>
                                                    <font style={{ fontSize: "18px", fontWeight: 'bold' }}>Correções:</font><p />
                                                    {filtrarLista(listAnFaq, 'tipo', 'C').map(ListDescricao)}<p />
                                                </li> : ''}
                                        </ul>
                                    </div>


                                </Box>
                                }
                            />
                        </Box>
                        : ''}
                    <FAQDialog open={open} descricao={faqSelect.descricao} onClose={() => setOpen(false)} />
                </Paper>
            </Box>
        </ContainerPages >
    );
}