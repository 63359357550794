import * as React from 'react';
import { Divider, Stack } from '@mui/material';
import { SelectMenuAnFAQ, SelectFaqVersao } from '../../../../select';
import { TextFieldDefault } from '../../../../textfield';
import { Send } from '../../../../icons';
import { ButtonCancelar, ButtonIcon } from '../../../../button';
import useSac from '../../../../../hooks/useSac';
import { GeralContext, SacContext } from '../../../../../context';
import { TitleGrupos } from '../../../../typography';
import useFaq from '../../../../../hooks/useFaq';

export default function DialogAnotarFAQ() {
    const { toggleAcao, codigo } = React.useContext(SacContext);
    const [atend, setAtend] = React.useState({ codigo: codigo, tipo: '', texto: '', versao: '' });
    const { toggleMensagem } = React.useContext(GeralContext);
    const { putSac } = useSac();

    const { getFaqPendentes, postAnotacaoFaq, listFaqPend } = useFaq();

    React.useEffect(() => {
        getFaqPendentes();
    }, [])

    const handleChange = (event) => {
        setAtend((prevalue) => {
            return {
                ...prevalue,
                [event.target.name]: event.target.value
            }
        })
    }

    function salvar() {
        if (atend.texto === '' || atend.versao === '' || atend.tipo === '') {
            toggleMensagem('Todos os campos devem ser preenchidos.', 'warning');
            return;
        } else {
            setTimeout(() => {
                postAnotacaoFaq(atend);
            }, 2500)
            putSac('anotacoes', atend);
        }
        toggleAcao(codigo, 'sac');
    }

    return (
        <>
            <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title='Anotações FAQ' /></Divider>
            <TextFieldDefault id='texto' name='texto' autoFocus={true} onChange={handleChange} value={atend.texto} label='Anotações' multiline rows={5} />
            <SelectMenuAnFAQ value={atend.tipo} onChange={handleChange} />
            <SelectFaqVersao id='versao' value={atend.versao} onChange={handleChange} />
            <Stack direction='row' spacing={1} justifyContent='flex-end' paddingRight={1} paddingBottom={1} paddingTop={1}>
                <ButtonIcon icon={<Send />} caption='Anotar' onClick={() => salvar()} color='green' />
                <ButtonCancelar />
            </Stack>
        </>
    );
}
