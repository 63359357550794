import * as React from 'react';
import useSac from '../../hooks/useSac';
import { Grid, FormControlLabel, Box, Divider, LinearProgress, Switch } from '@mui/material';
import { AccordionAtendimentos } from '../../components/accordion/index';
import { SelectAssuntos, SelectClientes, SelectPesquisa, SelectSetores, SelectTipoData, SelectUsuarios } from '../../components/select';
import { TextFieldDefault } from '../../components/textfield';
import { formatInputDate } from '../../utils/date';
import { ContainerPages } from '../../components/container';
import { ButtonIcon } from '../../components/button';
import { SearchIcon } from '../../components/icons';
import { TextConteudo, Title, TitleGrupos } from '../../components/typography';
import { listaLength } from '../../utils/string';
import { getThemeContraste } from '../../utils/theme';
import LayoutSac from '../../components/layout';
import { orderList } from '../../utils/geral';

export default function SacFinalizados() {
    const { getFinalizados, loadingSac, listFinalizados, loading } = useSac();
    const [params, setParams] = React.useState({ setor: '', assunto: '', filtro: "Conteudo", pesquisa: '', atendente: null, pessoa: null, grupo: false, tpData: 'F', datai: formatInputDate(Date.now()), dataf: formatInputDate(Date.now()), versao_copia: '' });
    const [codigo, setCodigo] = React.useState(0);

    function ListaAtendimentos(atendimento) {
        return (<span onClick={() => setCodigo(atendimento.codigo)} key={atendimento.codigo}>
            <AccordionAtendimentos codigo={codigo}
                color={true} atendimento={atendimento} loading={loading} />
        </span>
        )
    }

    const handleChange = (event) => {
        let name = (event.target.name === 'tipo' ? 'setor' : event.target.name);
        let value = (name === 'grupo' ? event.target.checked : event.target.value);
        setParams((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        })
    }

    const handleSubmit = e => {
        e.preventDefault();
        getFinalizados(params)
    };

    return (
        <ContainerPages title={`Finalizados - (${listaLength(listFinalizados)})`} activeAuxBar={true} overflow={true}>
            <LayoutSac
                colL={<>
                    <Grid container spacing={0}>
                        <Grid item xs={12} lg={4}>
                            <SelectTipoData label='Tipo de Data' id='tpData' name='tpData' onChange={handleChange} value={params.tpData} />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <TextFieldDefault id='datai' name='datai' type='date' onChange={handleChange} value={formatInputDate(params.datai)} label='Data Inicial' />
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            <TextFieldDefault id='dataf' name='dataf' type='date' onChange={handleChange} value={formatInputDate(params.dataf)} label='Data Final' />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <SelectSetores label='Setor' id='setor' name='setor' onChange={handleChange} value={params.tipo} />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <SelectAssuntos label='Assunto' id='assunto' name='assunto' onChange={handleChange} value={params.assunto} />
                        </Grid>
                        <Grid item xs={12} lg={12}>
                            <SelectUsuarios id='atendente' onChange={(event, value) => setParams((prevalue) => {
                                return {
                                    ...prevalue,
                                    'atendente': value
                                }
                            })} value={params.atendente} label='Atendente' />
                        </Grid>
                        <Grid item xs={12} lg={10}>
                            <SelectClientes id='pessoa' onChange={(event, value) => setParams((prevalue) => {
                                return {
                                    ...prevalue,
                                    'pessoa': value
                                }
                            })} value={params.pessoa} label='Cliente' />
                        </Grid>
                        <Grid item xs={12} lg={2} >
                            <div style={{ textAlign: 'center' }}>
                                <TitleGrupos title='Grupo: ' />
                            </div>
                            <div style={{ textAlign: 'center', marginLeft: '20px' }}>
                                <FormControlLabel control={<Switch id='retornado' name='retornado' value={params.grupo} onChange={handleChange} color='green' size='small' />} style={{ color: getThemeContraste() }} />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Grid item xs={12} md={3} pt={1}>
                            <SelectPesquisa value={params.filtro} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <TextFieldDefault id='pesquisa' name='pesquisa' onChange={handleChange}
                                value={params.pesquisa}
                                label='Pesquisar'
                                typeButton='submit' />
                        </Grid>
                    </Grid>
                    <div style={{ textAlign: 'end' }}>
                        <ButtonIcon fullWidth={true} icon={<SearchIcon color='white' />} caption='Pesquisar' color='green' onClick={handleSubmit} />
                    </div>
                </>}
                colR=
                {<Box>
                    <Divider textAlign='left' >
                        <Title title='Resultado da Pesquisa' />
                    </Divider>
                    {loadingSac ? <LinearProgress /> :
                        listaLength(listFinalizados) > 0 ?
                            orderList(listFinalizados, 'data_finalizacao').map(ListaAtendimentos)
                            :
                            <Box pt={1}>
                                <TextConteudo text='Nenhum resultado encontrado.' />
                            </Box>
                    }</Box>}
            />
        </ContainerPages >
    );
}
