import React, { useEffect, useState } from 'react';
import { ContainerPages } from '../../components/container';
import { ButtonIcon, ButtonToolTip } from '../../components/button';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { SelectMovimento } from '../../components/select';
import { colorDetail, colorSac, getSetor } from '../../utils/sac';
import { GeralContext, SacContext } from '../../context';
import { Box, CircularProgress, Paper, Stack } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import LayoutSac from '../../components/layout';
import { HeaderSac } from '../../components/sac/header';
import { BodySac } from '../../components/sac/body';
import { getLayout, heightScreen } from '../../utils/theme';
import { AccordionAtendimentos, AccordionGrupos } from '../../components/accordion';
import { getMensagemFinal, listaLength } from '../../utils/string';
import { TextFieldDefault } from '../../components/textfield';
import { Check, Delete, HighLigth } from '../../components/icons';
import { StackJustify, StackLeft, StackRight } from '../../components/stack';
import { iniMensagemFinal } from '../../utils/constructor';
import { setLocal } from '../../utils/storage';
import useSac from '../../hooks/useSac';

function FinAvancado() {
    const { putSac } = useSac();
    const { listSac, sac, codigo, toggleCodigo, loading } = React.useContext(SacContext);
    const { toggleAtualizar } = React.useContext(GeralContext);
    const [mov, setMov] = useState({ codigo: -1, nome: '' });
    const [listaAt, setListaAt] = React.useState([]);
    const [totAt, setTotAt] = React.useState(0);
    const [showFinAvancado, setShowFinAvancado] = useState(false);
    const [mensagem, setMensagem] = React.useState(getMensagemFinal());

    useEffect(() => {
        toggleAtualizar(false);
        toggleCodigo(0);
        setShowFinAvancado(false);
        buscarAt();
    }, [mov])

    const buscarAt = () => {
        let lista = [];
        if (listaLength(listSac[0].grupos[0]?.atendimentos) > 0) {
            for (let i = 0; i < listaLength(listSac[0].grupos[0].atendimentos); i++) {
                if (listSac[0].grupos[0].atendimentos[i].grupo === mov.nome) {
                    lista.push(listSac[0].grupos[0].atendimentos[i].atendimentos);
                }
            }
        }
        setTotAt(listaLength(lista[0]));
        setListaAt(lista[0]);
    }

    const handleChange = (event) => {
        setMov(event.target.value);
    }

    const handleChangeFinAV = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        setMensagem({
            ...mensagem,
            [name]: value
        });
    }

    function ListaAtendimentos(atendimento) {
        return (getLayout() === 'padrao' || window.innerWidth < 768 ?
            <AccordionAtendimentos key={atendimento.codigo} codigo={codigo} showMenu={true}
                showMenuGeral={true} color={true} atendimento={atendimento} loading={!loading} />
            :
            <Paper key={atendimento.codigo} elevation={atendimento.codigo === codigo ? 10 : 0} style={{ background: 'inherit' }}>
                <Box style={colorSac(atendimento)} sx={colorDetail(atendimento, codigo)} mb={1} >
                    <HeaderSac codigo={atendimento.codigo} atendimento={atendimento} showMenuGeral={true} />
                </Box >
            </Paper>
        )

    }

    const limpar = () => {
        setLocal('msgFinalizar', JSON.stringify(iniMensagemFinal));
        setMensagem(iniMensagemFinal);
    }

    const salvar = () => {
        setLocal('msgFinalizar', JSON.stringify(mensagem));
    }

    function FinalizarLote() {
        let sacAtFin = '';
        for (let i = 0; i < listaLength(listaAt); i++) {
            sacAtFin = {
                "codigo": listaAt[i].codigo,
                "assunto": listaAt[i].assunto,
                "solucao": mensagem.solucao,
                "direto": false,
                "versao": mensagem.versao,
                "prioridade": listaAt[i].prioridade,
            }
            putSac('finalizar', sacAtFin);
            console.log('Finalizado o atendimento: ' + listaAt[i].codigo)
        }
        setMov({ codigo: -1, nome: '' });
    }

    return (
        <ContainerPages activeAuxBar={true} overflow={true} title='Finalizar Avançado' btnAux={
            <>
                <ButtonToolTip title='Finalizar movimento' color='red' icon={<CheckOutlinedIcon />} onClick={() => setShowFinAvancado(true)} />
                <ButtonToolTip title='Atualizar' color='green' icon={<RefreshIcon />} onClick={() => setMov({ codigo: -1 })} />
            </>
        } >
            <Paper>
                <LayoutSac
                    padrao={<></>}
                    colL={<>
                        <SelectMovimento value={mov} onChange={handleChange} setor={getSetor()} />
                        <AccordionGrupos grupo={mov.nome} registros={totAt} expanded={true}>
                            {listaLength(listaAt) > 0 ? listaAt.map(ListaAtendimentos) : ''}
                        </AccordionGrupos>
                    </>
                    }
                    colR={
                        showFinAvancado ?
                            <>
                                <Box pb={1}>
                                    <TextFieldDefault id='solucao' name='solucao' autoFocus={true} onChange={handleChangeFinAV} value={mensagem.solucao} label='Solução' multiline rows={5} />
                                    <TextFieldDefault id='versao' name='versao' onChange={handleChangeFinAV} value={mensagem.versao} label='Versão' />
                                </Box>
                                <StackJustify>
                                    <StackLeft>
                                        <ButtonToolTip color='red' icon={<Delete />} onClick={limpar} title='Limpar Mensagem' />
                                        <ButtonToolTip color='green' icon={<Check />} onClick={salvar} title='Salvar Mensagem' />
                                    </StackLeft>
                                    <StackRight>
                                        <ButtonIcon icon={<HighLigth color='white' />} caption='Fechar' onClick={() => setShowFinAvancado(false)} color='red' style={{ color: 'white' }} />
                                        <ButtonIcon icon={<Check color='white' />} caption='Finalizar lote' onClick={() => FinalizarLote()} color='green' style={{ color: 'white' }} />
                                    </StackRight>
                                </StackJustify>
                            </>
                            :
                            sac && codigo > 0 ?
                                <Box key={sac.codigo} p={1} >
                                    {sac.codigo ?
                                        <>
                                            <HeaderSac codigo={codigo} fixedMenu={true} showMenu={true} showMenuGeral={true} disabledDestaque={true} />
                                            <BodySac />
                                        </>
                                        : <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                            style={{ paddingTop: '25%' }}
                                        ><CircularProgress color='primary' /></Stack>}
                                </Box>
                                :
                                <img src='./app-assets/img/logo/Logo.svg' alt='logo redsis' style={{ height: heightScreen(-200), width: '100%', opacity: '0.1', WebkitFilter: 'grayscale(100%)', filter: 'grayscale(100%)' }} />
                    }
                />
            </Paper>
        </ContainerPages>
    )
}

export default FinAvancado;
