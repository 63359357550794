import React, { useEffect, useState } from "react";
import useToken from "../../hooks/useToken";

export default function Teste() {
    // const {token} = useToken();
    // const socket = new WebSocket('wss://server.redsis.com.br:8089', null, {
    //     headers: { Authorization: `Bearer ${token}` }
    // });

    // socket.onopen = function () {
    //     console.log("Conectado ao WebSocket");
    //     socket.send("Olá, servidor!"); // Enviando uma mensagem inicial
    // };

    // socket.onmessage = function (event) {
    //     console.log("Mensagem recebida:", event.data);
    //     document.getElementById("message").innerText = event.data; // Exibe no H1
    // };

    // socket.onerror = function (error) {
    //     console.error("Erro no WebSocket:", error);
    // };

    // socket.onclose = function () {
    //     console.log("Conexão encerrada");
    // };

    return (
        <h1 id="message">Aguardando mensagem...</h1>
    );
}