import * as React from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { TreeItem, TreeView } from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ButtonConcluido, ButtonFechar, ButtonToolTip } from '../../../../button';
import useRastreios from '../../../../../hooks/useRastreios';
import { SacContext } from '../../../../../context';
import { TitleGrupos } from '../../../../typography';
import { getThemeContraste } from '../../../../../utils/theme';
import { Check } from '../../../../icons';

export default function DialogRastreio(props) {
    const { getRastreios, rastreios, loading } = useRastreios();
    const { addRemoveRastreio } = useRastreios()
    const { toggleAcao, codigo } = React.useContext(SacContext)
    const [idFocus, setIdFocus] = React.useState(0);

    React.useEffect(() => {
        getRastreios();
        // eslint-disable-next-line
    }, [])


    function addRastreio(item) {
        addRemoveRastreio(codigo, item.codigo, 'post');
        toggleAcao(codigo, 'sac')
    }

    function ListarItens(item) {
        let child = rastreios.filter(it => it.master === item.codigo);
        return <TreeItem key={item.codigo} nodeId={item.codigo + ''} style={{ alignItems: 'center' }}
            label={<Stack direction='row' alignItems='center'>
                <TitleGrupos title={item.descricao} />
                {idFocus === item.codigo ?
                    <ButtonToolTip title='Selecionar' color='green' icon={<Check color='blue' />} onClick={() => addRastreio(item)} />
                    : ''}
            </Stack>} onClick={() => setIdFocus(item.codigo)}
        >
            {child.length === 0 ? "" : child.map(ListarItens)}
        </TreeItem>
    }

    return (
        <>
            <Divider textAlign="left" style={{ margin: '10px 0px 10px 0px' }}><TitleGrupos title='Rastreios' /></Divider>
            <Box pr={3}>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon color='iconColor' />}
                    defaultExpandIcon={<ChevronRightIcon color='iconColor' />}
                    defaultExpanded={['0']}
                    style={{ color: 'inherit' }}
                >
                    <TreeItem nodeId={'0'} label={<TitleGrupos title='Rastreios' />} >
                        {loading ? '' : rastreios.filter(it => it.master === 0).map(ListarItens)}
                    </TreeItem>
                </TreeView>
                <p />
                <Stack direction='row' spacing={1} justifyContent='flex-end'>
                    <ButtonFechar />
                </Stack>
                <p />
            </Box>
        </>
    );
}
