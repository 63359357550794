import React from 'react';
import { Stack } from '@mui/material';

export function StackJustify(props) {
    return (<Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={props.spacing ? props.spacing : 0}
    >
        {props.children}
    </Stack>
    );
}

export function StackLeft(props) {
    return (<Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={2}
        onClick={props.onClick}
    >
        {props.children}
    </Stack>
    );
}

export function StackRight(props) {
    return (<Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={props.spacing ? props.spacing  : 2}
        style={props.style}
    >
        {props.children}
    </Stack>
    );
}