import * as React from 'react';
import { ContainerPages } from '../../components/container';
import { useParams } from 'react-router-dom';
import { criptografar, descriptografar } from '../../utils/crypto';
import { CampararData } from '../../utils/date';
import useAnexosExternos from '../../hooks/useAnexosExternos';
import { Box, Button, Divider, LinearProgress, List, ListItem, Typography } from '@mui/material';
import { ButtonLinkIcon, ButtonToolTip } from '../../components/button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import { listaLength } from '../../utils/string';
import { TitleSmall, TitleGrupos } from '../../components/typography';
import { StackJustify, StackLeft, StackRight } from '../../components/stack';
import useToken from "../../hooks/useToken";
import UpdateIcon from '@mui/icons-material/Update';
import { getThemeContraste } from '../../utils/theme';
import { FileIcon } from '../../components/icons';

export default function SharePage() {
    const { id } = useParams();
    const desc = descriptografar(id);
    const codigo = desc.split('.')[0];
    const { getTokenAnexosSac, auth } = useToken();
    const [selectedFile, setSelectedFile] = React.useState(undefined);
    const { getAnexos, getFile, postAnexos, anexos, deleteAnexos, progress, statusBar } = useAnexosExternos();
    const hash = criptografar(codigo);
    const [ , setIsDragOver] = React.useState(false);

    React.useEffect(() => {
        getTokenAnexosSac();
    }, [])

    React.useEffect(() => {
        getAnexos(codigo);
    }, [progress === 100])

    React.useEffect(() => {
        getAnexos(codigo);
        setSelectedFile(undefined);
    }, [statusBar === false, auth])

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile) {
            postAnexos(codigo, selectedFile)
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = () => {
        setIsDragOver(false);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        setIsDragOver(false);

        const files = event.dataTransfer.files;
        handleFiles(files);
    };

    const handleFiles = (files) => {
        for (const file of files) {
            setSelectedFile(file);
        }
    };

    function ListarAnexos(anexo, a) {
        return (
            <ListItem key={a}
            >
                {/* <StackJustify>
                    <a href={`https://server.redsis.com.br:8085/${codigo}/${anexo.nome}`} target="_blank" style={{ textDecoration: 'none' }}><TitleSmall title={anexo.nome} /></a>
                    <ButtonToolTip icon={<ClearIcon fontSize='25px' />} onClick={() => deleteAnexos(codigo, anexo.nome)} color='red' title='Excluir' />
                </StackJustify> */}
                 <StackJustify>                
                    <span
                        onClick={(event) => {
                            event.stopPropagation(); // Impede cliques duplicados
                            getFile(codigo, anexo.nome);
                        }}
                        style={{ cursor: 'pointer', display: 'inline-flex' }}
                    >
                        <FileIcon fontSize='18px' color='iconColor' />
                        <TitleGrupos title={anexo.nome} />
                    </span>
                    <ButtonToolTip icon={<ClearIcon fontSize='25px' />} onClick={() => deleteAnexos(codigo, anexo.nome)} color='red' title='Excluir' />
                </StackJustify>
            </ListItem>
        );
    }

    return (
        <ContainerPages activeAuxBar={true} title='Área compartilhada' navBarSimple={true}>
            {CampararData(desc.split('.')[1]) ?
                <>
                    <List >
                        <Box p={1}>
                            <TitleGrupos title='Itens compartilhados:' />
                            {listaLength(anexos) > 0 ? anexos.map(ListarAnexos) : <TitleSmall title='Nenhum item compartilhado.' />}
                            <br />
                            {!selectedFile ?
                                <label htmlFor="upload-file">
                                    <div
                                        onDragOver={handleDragOver}
                                        onDragLeave={handleDragLeave}
                                        onDrop={handleDrop}
                                        style={{ padding: '50px', height: '100px', border: `1px  dashed ${getThemeContraste()}`, textAlign: 'center' }}

                                    >
                                        <Typography variant="h6" gutterBottom>
                                            Arraste e solte os arquivos aqui
                                        </Typography>
                                        <Typography variant="subtitle1" gutterBottom>
                                            Ou clique para selecionar os arquivos
                                        </Typography>
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            style={{ display: 'none' }}
                                            id="upload-file"
                                            accept=".jpg, .jpeg, .png, .gif, .bmp, .txt, .zip, .rar, .xls, .xlsx, .doc, .docx, .ppt, .pptx"
                                        />

                                    </div>
                                </label>
                                : ''}
                        </Box>
                    </List>
                    <Box p={1} style={{ marginLeft: '-10px ', marginRight: '10px' }}>
                        {statusBar && progress < 100 ? <><LinearProgress variant="determinate" value={progress} /><div style={{ textAlign: 'center' }}><TitleGrupos title={`${progress} %`} /></div></> :
                            <Box pl={2}>
                                {selectedFile && (
                                    <>
                                        <Divider />
                                        <StackLeft>
                                            <TitleGrupos title="Arquivo:" />
                                            {selectedFile ? <TitleSmall title={selectedFile.name} /> : ''}<br />
                                        </StackLeft>
                                        <StackRight >
                                            <Box>
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    size='small'
                                                    startIcon={<CloudUploadIcon />}
                                                    color='blue'
                                                    onClick={handleUpload}
                                                >
                                                    Enviar
                                                </Button>
                                                <span style={{ margin: '5px' }} />
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    size='small'
                                                    startIcon={<DeleteIcon />}
                                                    color='red'
                                                    onClick={() => setSelectedFile(undefined)}
                                                >
                                                    Cancelar
                                                </Button>
                                            </Box>
                                        </StackRight>
                                    </>
                                )}
                            </Box>
                        }
                    </Box >
                </>
                :
                <Box p={1}>
                    <StackJustify>
                        <TitleGrupos title='A validade deste link expirou! Gere um novo link para continuar.' />
                        <ButtonToolTip />
                        <ButtonLinkIcon to={`/comp/${hash}`} icon={<UpdateIcon />} title='Novo link' />
                    </StackJustify>
                </Box>
            }
        </ContainerPages>
    );
};
