import { useContext, useState } from "react";
import axios from "../services/api";
import useToken from "../hooks/useToken";
import { GeralContext } from "../context";

const useAnexos = () => {
  const { token } = useToken();
  const [anexos, setAnexos] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [statusBar, setStatusBar] = useState(false);
  const { toggleMensagem } = useContext(GeralContext);
  let isRequestInProgress = false;
  

  let config = {
    headers: {
      'Authorization': token
    },
  };

  const getAnexos = async (codigo) => {
    try {
      const response = await axios.get(`/atendimentos/${codigo}/anexos`, config)
      setAnexos(response.data.result);
    } catch (error) {
    }
  }

  // const getFile = async (codigo, nome) => {
  //   setDownloading(true);
  //   setDownloadProgress(0);

  //   axios.get(`/downloads/anexos/${codigo}%2F${nome}`,
  //     {
  //       responseType: 'blob',
  //       ...config
  //     })
  //     .then(function (response) {
  //       var blob = new Blob([response.data], {
  //         type: response.headers['content-type']
  //       });
  //       var url = window.URL.createObjectURL(blob)
  //       window.open(url);
  //     })
  //     .catch(function (error) {
  //       console.log(error)
  //     })

  // }

  // const getFile = async (codigo, nome) => {
  //   setStatusBar(true);
  //   setProgress(0);
  //   axios
  //     .get(`/downloads/anexos/${codigo}%2F${nome}`, {
  //       responseType: "blob",
  //       ...config,
  //       onDownloadProgress: (progressEvent) => {
  //         const percentCompleted = Math.round(
  //           (progressEvent.loaded * 100) / progressEvent.total
  //         );
  //         setProgress(percentCompleted);
  //       },
  //     })
  //     .then((response) => {
  //       const blob = new Blob([response.data], {
  //         type: response.headers["content-type"],
  //       });

  //       const contentDisposition = response.headers["content-disposition"];
  //       if (contentDisposition && contentDisposition.includes("attachment")) {
  //         console.warn("Download já iniciado automaticamente pelo servidor.");
  //         return; // Evita o segundo download
  //       }

  //       const url = window.URL.createObjectURL(blob);
  //       // Criando um link de download
  //       if (window.navigator.msSaveOrOpenBlob) {
  //         // Para IE e Edge
  //         window.navigator.msSaveOrOpenBlob(blob, nome);
  //       } else {
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement("a");
  //         a.style.display = "none";
  //         a.href = url;
  //         a.download = decodeURIComponent(nome); // Garante o nome correto do arquivo
  //         document.body.appendChild(a);
  //         a.click();
  //         document.body.removeChild(a);
  //         window.URL.revokeObjectURL(url); // Libera a memória
  //         setTimeout(() => window.URL.revokeObjectURL(url), 100);
  //       }


  //       setProgress(100); // Define 100% após conclusão
  //       setTimeout(() => setStatusBar(false), 1000); // Oculta a barra após um tempo
  //       window.open(url);
  //     })
  //     .catch((error) => {
  //       console.error("Erro no download:", error);
  //       setStatusBar(false);
  //     });
  // };

  const getFile = async (codigo, nome) => {
    if (isRequestInProgress) return; // Impede que o GET seja disparado novamente enquanto uma requisição estiver em andamento
    
    isRequestInProgress = true; // Marca a requisição como em andamento
    
    setStatusBar(true);
    setProgress(0);
  
    axios
      .get(`/downloads/anexos/${codigo}%2F${nome}`, {
        responseType: "blob",
        ...config,
        onDownloadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      })
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
  
        const contentDisposition = response.headers["content-disposition"];
        if (contentDisposition && contentDisposition.includes("attachment")) {
          console.warn("Download já iniciado automaticamente pelo servidor.");
          return; // Evita o segundo download
        }
  
        const url = window.URL.createObjectURL(blob);
        // Criando um link de download
        if (window.navigator.msSaveOrOpenBlob) {
          // Para IE e Edge
          window.navigator.msSaveOrOpenBlob(blob, nome);
        } else {
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = decodeURIComponent(nome); // Garante o nome correto do arquivo
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url); // Libera a memória
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
        }
  
        setProgress(100); // Define 100% após conclusão
        setTimeout(() => setStatusBar(false), 1000); // Oculta a barra após um tempo
        window.open(url);
      })
      .catch((error) => {
        console.error("Erro no download:", error);
        setStatusBar(false);
      })
      .finally(() => {
        isRequestInProgress = false; // Marca a requisição como finalizada
      });
  };

  const postAnexos = async (codigo, arq) => {
    setStatusBar(true);
    setProgress(0);
    const formData = new FormData();
    formData.append('file', arq);
    axios.post(`/atendimentos/${codigo}/anexos`, formData, {
      headers: {
        'Authorization': token
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    })
      .then(response => {
        setStatusBar(false);
        toggleMensagem(`Arquivo anexado com sucesso!`, 'success');
      })
      .catch(error => {
        toggleMensagem(error, 'error');
      });
  }

  const deleteAnexos = async (codigo, nome) => {
    try {
      setStatusBar(true);
      const urlEnconded = nome;
      await axios.delete(`/atendimentos/${codigo}/anexos/${urlEnconded}`, config);
      setProgress(0)
      setStatusBar(false);

    } catch (error) {
      console.log(error)
      setProgress(0)
      setStatusBar(false);

    }
  }

  const postCopia = async (versao, arq) => {
    setStatusBar(true);
    setProgress(0);
    const formData = new FormData();
    formData.append('file', arq);
    axios.post(`releases/redsis/${versao}`, formData, {
      headers: {
        'Authorization': token
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
      },
    })
      .then(response => {
        setStatusBar(false);
        toggleMensagem('Arquivo enviado com sucesso!', 'success');
      })
      .catch(error => {
        toggleMensagem('Não foi possível enviar o arquivo!', 'error');
      });
  }

  return { getAnexos, postAnexos, deleteAnexos, anexos, progress, setProgress, statusBar, postCopia, getFile }
}
export default useAnexos;